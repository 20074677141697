import CryptoJS from 'crypto-js';
import CryptoU8array from './enc-u8array'

const key = CryptoJS.enc.Utf8.parse('abrtyfghjkulebjp');
const iv = CryptoJS.enc.Utf8.parse('1234567890123456');
export default {
    // 加密
    encrypt(word) {
        let srcs = CryptoJS.enc.Utf8.parse(word);
    
        let encrypted = CryptoJS.AES.encrypt(srcs, key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    },
    // 解密
    decrypt(word) {
        var decrypt = CryptoJS.AES.decrypt(word, key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypt.toString(CryptoJS.enc.Utf8);
    },
    proce(buffer){
        let view = new Uint8Array(buffer)
    
        // 将Uint8Array 转成 WordArray
        let contentWA = CryptoU8array.u8array.parse(view)
        // base64字符串
        let dcBase64String = contentWA.toString(CryptoJS.enc.Base64)
    
        // 解密
        let decryptedData = CryptoJS.AES.decrypt(dcBase64String, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        })
    
        // 把解密后的对象再转为base64编码,这步是关键,跟解密文字不同
        let d64 = decryptedData.toString(CryptoJS.enc.Base64)
        let url = 'data:image/png;base64,' + d64
        return url
    }
};