<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default  {
  name:  'App',
    metaInfo() {
        return {
            title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
            titleTemplate: title => {
                return title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
            }
        }
    }
}
</script>
<style>
body .el-dialog img[alt=upload-img]{
  display:none !important;
}
.w-e-full-screen-container{
  z-index: 10 !important;
}
.w-e-bar-item button .title{
  margin-bottom:0px !important;
}
</style>
