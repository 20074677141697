import request from '@/utils/request'

// 查询
export function query(query) {
  return request({
    url: '/system/novel/novel/query',
    method: 'get',
    params: query
  })
}

// 查询
export function info(id) {
  return request({
    url: '/system/novel/novel/info/' + id,
    timeout: 100000,
    method: 'get',
  })
}

// 新增
export function add(data) {
  return request({
    url: '/system/novel/novel/add',
    method: 'post',
    data: data
  })
}

// 修改
export function update(data) {
  return request({
    url: '/system/novel/novel/edit',
    method: 'put',
    data: data
  })
}

// 批量删除
export function del(ids) {
  return request({
    url: '/system/novel/novel/delList',
    method: 'delete',
    data: ids
  })
}

// 查询小说章节内容
export function content(id) {
  return request({
    url: '/system/novel/novel/content/' + id,
    method: 'get'
  })
}

// 批量生成小说分片路径
export function createNovelAllHtmlPath() {
  return request({
    url: '/system/novel/novel/createNovelAllHtmlPath',
    timeout: 100000,
    method: 'put'
  })
}


