var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      this.type == "url"
        ? _c("el-upload", {
            ref: "upload",
            staticStyle: { display: "none" },
            attrs: {
              action: _vm.uploadUrl,
              "before-upload": _vm.handleBeforeUpload,
              multiple: "",
              "on-success": _vm.handleUploadSuccess,
              "on-error": _vm.handleUploadError,
              name: "file",
              "show-file-list": false,
              headers: _vm.headers,
            },
          })
        : _vm._e(),
      this.type == "url"
        ? _c("el-upload", {
            ref: "uploadVideo",
            staticStyle: { display: "none" },
            attrs: {
              action: _vm.uploadUrlVideo,
              "before-upload": _vm.handleBeforeUploadVideo,
              "on-success": _vm.handleUploadSuccessVideo,
              "on-error": _vm.handleUploadErrorVideo,
              name: "file",
              "show-file-list": false,
              headers: _vm.headersVideo,
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            size: "mini",
            title: "选择资源",
            visible: _vm.showSource,
            width: "800px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSource = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                size: "mini",
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "链接文字", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入链接文字", clearable: "" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "资源列表", prop: "resource" } },
                [
                  _vm.form.resource
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-card",
                            [
                              _c(
                                "el-table",
                                { attrs: { data: [_vm.form.resource] } },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "id",
                                      align: "center",
                                      prop: "id",
                                      width: "55",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "标题",
                                      align: "center",
                                      prop: "title",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "标题",
                                      align: "center",
                                      prop: "title",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm.queryParams2.resType === 1
                                                ? _c(
                                                    "el-tag",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                      },
                                                    },
                                                    [_vm._v("电影")]
                                                  )
                                                : _vm._e(),
                                              _vm.queryParams2.resType === 2
                                                ? _c(
                                                    "el-tag",
                                                    {
                                                      attrs: {
                                                        type: "success",
                                                      },
                                                    },
                                                    [_vm._v("动漫")]
                                                  )
                                                : _vm._e(),
                                              _vm.queryParams2.resType === 3
                                                ? _c(
                                                    "el-tag",
                                                    { attrs: { type: "info" } },
                                                    [_vm._v("小说")]
                                                  )
                                                : _vm._e(),
                                              _vm.queryParams2.resType === 4
                                                ? _c(
                                                    "el-tag",
                                                    {
                                                      attrs: {
                                                        type: "warning",
                                                      },
                                                    },
                                                    [_vm._v("图集")]
                                                  )
                                                : _vm._e(),
                                              _vm.queryParams2.resType === 5
                                                ? _c(
                                                    "el-tag",
                                                    {
                                                      attrs: { type: "danger" },
                                                    },
                                                    [_vm._v("短视频")]
                                                  )
                                                : _vm._e(),
                                              scope.row.resType === 6
                                                ? _c(
                                                    "el-tag",
                                                    {
                                                      style: {
                                                        backgroundColor:
                                                          "rgb(255, 241, 232)",
                                                        color: "#f97824",
                                                        borderColor: "#f97824",
                                                      },
                                                    },
                                                    [_vm._v("动态 ")]
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      909164963
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "封面",
                                      align: "center",
                                      prop: "thumbUrl",
                                      width: "120",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              [1, 2, 3, 5].indexOf(
                                                _vm.queryParams2.resType
                                              ) > -1
                                                ? _c("el-image", {
                                                    staticStyle: {
                                                      width: "54.5px",
                                                      height: "73.5px",
                                                    },
                                                    attrs: {
                                                      fit: "cover",
                                                      src:
                                                        scope.row.thumbUrl ||
                                                        scope.row.coverImg,
                                                      "preview-src-list": [
                                                        scope.row.thumbUrl ||
                                                          scope.row.coverImg,
                                                      ],
                                                      "data-src":
                                                        scope.row.thumbUrl ||
                                                        scope.row.coverImg,
                                                      lazy: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.queryParams2.resType === 4
                                                ? _c("el-image", {
                                                    staticStyle: {
                                                      width: "54.5px",
                                                      height: "73.5px",
                                                    },
                                                    attrs: {
                                                      fit: "cover",
                                                      src: scope.row.coverUrl,
                                                      "preview-src-list": [
                                                        scope.row.coverUrl,
                                                      ],
                                                      lazy: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.queryParams2.resType === 6 &&
                                              scope.row.thumbnailUrls
                                                ? _c(
                                                    "el-carousel",
                                                    {
                                                      attrs: {
                                                        "indicator-position":
                                                          "none",
                                                        arrow: "never",
                                                        height: "50px",
                                                      },
                                                    },
                                                    _vm._l(
                                                      scope.row.thumbnailUrls.split(
                                                        ","
                                                      ),
                                                      function (item) {
                                                        return _c(
                                                          "el-carousel-item",
                                                          { key: item },
                                                          [
                                                            _c("el-image", {
                                                              staticStyle: {
                                                                width: "54.5px",
                                                                height:
                                                                  "73.5px",
                                                              },
                                                              attrs: {
                                                                fit: "cover",
                                                                src: item,
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      405752044
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: { label: "资费", align: "center" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              scope.row.vip > 0
                                                ? _c(
                                                    "el-tag",
                                                    {
                                                      attrs: {
                                                        type: "success",
                                                        size: "mini",
                                                      },
                                                    },
                                                    [_vm._v("VIP")]
                                                  )
                                                : scope.row.price > 0
                                                ? _c(
                                                    "el-tag",
                                                    {
                                                      attrs: {
                                                        type: "danger",
                                                        size: "mini",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "付费(" +
                                                          _vm._s(
                                                            scope.row.price
                                                          ) +
                                                          "金币) "
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "el-tag",
                                                    { attrs: { size: "mini" } },
                                                    [_vm._v("免费")]
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      299883338
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "操作",
                                      align: "center",
                                      "class-name": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "small",
                                                    type: "danger",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleFilmRemove(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("移除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2023203526
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-card",
                        [
                          _c(
                            "el-form",
                            {
                              ref: "queryForm2",
                              attrs: {
                                model: _vm.queryParams2,
                                size: "small",
                                inline: true,
                                "label-width": "68px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "", prop: "keyword" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "220px" },
                                    attrs: {
                                      placeholder: "请输入关键字",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleFilmQuery($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.queryParams2.keyword,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.queryParams2,
                                          "keyword",
                                          $$v
                                        )
                                      },
                                      expression: "queryParams2.keyword",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "", prop: "resType" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "220px" },
                                      attrs: { placeholder: "选择类型" },
                                      on: { change: _vm.handleFilmQuery },
                                      model: {
                                        value: _vm.queryParams2.resType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.queryParams2,
                                            "resType",
                                            $$v
                                          )
                                        },
                                        expression: "queryParams2.resType",
                                      },
                                    },
                                    _vm._l(_vm.resTypeList, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        icon: "el-icon-search",
                                        size: "mini",
                                      },
                                      on: { click: _vm.handleFilmQuery },
                                    },
                                    [_vm._v("搜索")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading2,
                                  expression: "loading2",
                                },
                              ],
                              ref: "multipleTable",
                              attrs: { data: _vm.tableData2, height: "500" },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "id",
                                  align: "center",
                                  prop: "id",
                                  width: "55",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "标题",
                                  align: "center",
                                  prop: "title",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "封面",
                                  align: "center",
                                  prop: "thumbUrl",
                                  width: "120",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        [1, 2, 3, 5].indexOf(
                                          _vm.queryParams2.resType
                                        ) > -1
                                          ? _c("el-image", {
                                              staticStyle: {
                                                width: "54.5px",
                                                height: "73.5px",
                                              },
                                              attrs: {
                                                fit: "cover",
                                                src:
                                                  scope.row.thumbUrl ||
                                                  scope.row.coverImg,
                                                "preview-src-list": [
                                                  scope.row.thumbUrl ||
                                                    scope.row.coverImg,
                                                ],
                                                "data-src":
                                                  scope.row.thumbUrl ||
                                                  scope.row.coverImg,
                                                lazy: "",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.queryParams2.resType === 4
                                          ? _c("el-image", {
                                              staticStyle: {
                                                width: "54.5px",
                                                height: "73.5px",
                                              },
                                              attrs: {
                                                fit: "cover",
                                                src: scope.row.coverUrl,
                                                "preview-src-list": [
                                                  scope.row.coverUrl,
                                                ],
                                                lazy: "",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.queryParams2.resType === 6 &&
                                        scope.row.thumbnailUrls
                                          ? _c(
                                              "el-carousel",
                                              {
                                                attrs: {
                                                  "indicator-position": "none",
                                                  arrow: "never",
                                                  height: "50px",
                                                },
                                              },
                                              _vm._l(
                                                scope.row.thumbnailUrls.split(
                                                  ","
                                                ),
                                                function (item) {
                                                  return _c(
                                                    "el-carousel-item",
                                                    { key: item },
                                                    [
                                                      _c("el-image", {
                                                        staticStyle: {
                                                          width: "54.5px",
                                                          height: "73.5px",
                                                        },
                                                        attrs: {
                                                          fit: "cover",
                                                          src: item,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "资费", align: "center" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.vip > 0
                                          ? _c(
                                              "el-tag",
                                              {
                                                attrs: {
                                                  type: "success",
                                                  size: "mini",
                                                },
                                              },
                                              [_vm._v("VIP")]
                                            )
                                          : scope.row.price > 0
                                          ? _c(
                                              "el-tag",
                                              {
                                                attrs: {
                                                  type: "danger",
                                                  size: "mini",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "付费(" +
                                                    _vm._s(scope.row.price) +
                                                    "金币)"
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "el-tag",
                                              { attrs: { size: "mini" } },
                                              [_vm._v("免费")]
                                            ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                              _c("el-table-column", {
                                attrs: { label: "操作", align: "center" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "mini",
                                              icon: "el-icon-plus",
                                              type: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleFilmAdd(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("添加 ")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                          _c("pagination", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.total2 > 0,
                                expression: "total2 > 0",
                              },
                            ],
                            attrs: {
                              small: "",
                              layout: "prev, pager, next",
                              total: _vm.total2,
                              page: _vm.queryParams2.pageNum,
                              limit: _vm.queryParams2.pageSize,
                            },
                            on: {
                              "update:page": function ($event) {
                                return _vm.$set(
                                  _vm.queryParams2,
                                  "pageNum",
                                  $event
                                )
                              },
                              "update:limit": function ($event) {
                                return _vm.$set(
                                  _vm.queryParams2,
                                  "pageSize",
                                  $event
                                )
                              },
                              pagination: _vm.getFilmList,
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { ref: "editor", staticClass: "editor", style: _vm.styles }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }