<template>
  <div class="app-container home">
    <h2 style="text-align: center">潘朵拉管理系统后台</h2>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      // 版本号
      version: "3.4.0",
    };
  },
  methods: {
    goTarget(href) {
      window.open(href, "_blank");
    },
  },
};
</script>

<style scoped lang="scss"></style>
