import request from '@/utils/request'

// 查询列表
export function list(params) {
  return request({
    url: '/system/clip/clip/list',
    method: 'get',
    params: params
  })
}

// 新增
export function add(data) {
  return request({
    url: '/system/clip/clip/add',
    method: 'post',
    data: data
  })
}

// 修改
export function update(data) {
  return request({
    url: '/system/clip/clip/edit',
    method: 'put',
    data: data
  })
}

// 删除
export function del(id) {
  return request({
    url: '/system/clip/clip/del/' + id,
    method: 'delete'
  })
}

// 查询
export function info(id) {
  return request({
    url: '/system/clip/clip/info/' + id,
    method: 'get',
  })
}
