import request from '@/utils/request'

// 查询
export function query(query) {
  return request({
    url: '/system/image/image/query',
    method: 'get',
    params: query
  })
}


// 查询
export function getImageBurstList(query) {
  return request({
    url: '/system/image/image/getImageBurstList',
    method: 'get',
    params: query,
    timeout: 60*1000
  })
}

// 查询
export function info(id) {
  return request({
    url: '/system/image/image/info/' + id,
    method: 'get',
  })
}

// 新增
export function add(data) {
  return request({
    url: '/system/image/image/add',
    method: 'post',
    data: data
  })
}

// 关联图集评论
export function sharedResourceCommentAssociationAdd(data) {
  return request({
    url: '/system/sharedResourceCommentAssociation/add',
    method: 'post',
    data: data
  })
}
// 删除关联图集评论
export function sharedResourceCommentAssociationDel(id) {
  return request({
    url: `/system/sharedResourceCommentAssociation/del?resId=${id}&resType=GRAPHICS`,
    method: 'delete'
  })
}

// 修改
export function update(data) {
  return request({
    url: '/system/image/image/edit',
    method: 'put',
    data: data
  })
}

// 批量删除
export function del(ids) {
  return request({
    url: '/system/image/image/delList',
    method: 'delete',
    data: ids
  })
}

// 查询电影视频地址
export function play(id) {
  return request({
    url: '/system/image/image/play/' + id,
    method: 'get'
  })
}
