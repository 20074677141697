import request from '@/utils/request'

// 查询
export function query(query) {
  return request({
    url: '/system/film/film/query',
    method: 'get',
    params: query
  })
}

// 查询
export function info(id) {
  return request({
    url: '/system/film/film/info/' + id,
    method: 'get',
  })
}

// 新增
export function add(data) {
  return request({
    url: '/system/film/film/add',
    method: 'post',
    data: data
  })
}

// 修改
export function update(data) {
  return request({
    url: '/system/film/film/edit',
    method: 'put',
    data: data
  })
}

// 批量删除
export function del(ids) {
  return request({
    url: '/system/film/film/delList',
    method: 'delete',
    data: ids
  })
}

// 查询电影视频地址
export function play(id) {
  return request({
    url: '/system/film/film/play/' + id,
    method: 'get'
  })
}
