<template>
  <div>
    <el-upload :action="uploadUrl" :before-upload="handleBeforeUpload" multiple :on-success="handleUploadSuccess"
               :on-error="handleUploadError" name="file" :show-file-list="false" :headers="headers"
               style="display: none"
               ref="upload" v-if="this.type == 'url'">
    </el-upload>
    <el-upload :action="uploadUrlVideo" :before-upload="handleBeforeUploadVideo" :on-success="handleUploadSuccessVideo"
               :on-error="handleUploadErrorVideo" name="file" :show-file-list="false" :headers="headersVideo"
               style="display: none"
               ref="uploadVideo" v-if="this.type == 'url'">
    </el-upload>

    <el-dialog size="mini" title="选择资源" :visible.sync="showSource" width="800px" append-to-body>
      <el-form size="mini" ref="form" :rules="rules" :model="form" label-width="100px">
        <el-form-item label="链接文字" prop="title">
          <el-input v-model="form.title" placeholder="请输入链接文字" clearable/>
        </el-form-item>
        <el-form-item label="资源列表" prop="resource">
          <div v-if="form.resource">
            <el-card>
              <el-table :data="[form.resource]">
                <el-table-column label="id" align="center" prop="id" width="55"></el-table-column>
                <el-table-column label="标题" align="center" prop="title" show-overflow-tooltip></el-table-column>
                <el-table-column label="标题" align="center" prop="title" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-tag type="primary" v-if="queryParams2.resType === 1">电影</el-tag>
                    <el-tag type="success" v-if="queryParams2.resType === 2">动漫</el-tag>
                    <el-tag type="info" v-if="queryParams2.resType === 3">小说</el-tag>
                    <el-tag type="warning" v-if="queryParams2.resType === 4">图集</el-tag>
                    <el-tag type="danger" v-if="queryParams2.resType === 5">短视频</el-tag>
                    <el-tag :style="{
                      backgroundColor: 'rgb(255, 241, 232)',
                      color: '#f97824',
                      borderColor: '#f97824',
                    }" v-if="scope.row.resType === 6">动态
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="封面" align="center" prop="thumbUrl" width="120">
                  <template slot-scope="scope">
                    <el-image v-if="[1, 2, 3, 5].indexOf(queryParams2.resType) > -1" fit="cover"
                              style="width: 54.5px; height: 73.5px"  :src="scope.row.thumbUrl||scope.row.coverImg"
                      :preview-src-list="[scope.row.thumbUrl||scope.row.coverImg]"
                       :data-src="scope.row.thumbUrl||scope.row.coverImg" lazy></el-image>
                    <el-image v-if="queryParams2.resType === 4" fit="cover" style="width: 54.5px; height: 73.5px"
                              :src="scope.row.coverUrl" :preview-src-list="[scope.row.coverUrl]" lazy></el-image>
                    <el-carousel indicator-position="none" arrow="never" height="50px" v-if="
                      queryParams2.resType === 6 && scope.row.thumbnailUrls
                    ">
                      <el-carousel-item v-for="item in scope.row.thumbnailUrls.split(',')" :key="item">
                        <el-image fit="cover" style="width: 54.5px; height: 73.5px" :src="item"></el-image>
                      </el-carousel-item>
                    </el-carousel>
                  </template>
                </el-table-column>
                <el-table-column label="资费" align="center">
                  <template slot-scope="scope">
                    <el-tag v-if="scope.row.vip > 0" type="success" size="mini">VIP</el-tag>
                    <el-tag v-else-if="scope.row.price > 0" type="danger" size="mini">付费({{ scope.row.price }}金币)
                    </el-tag>
                    <el-tag v-else size="mini">免费</el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" class-name="">
                  <template slot-scope="scope">
                    <el-button size="small" type="danger" @click="handleFilmRemove(scope.row)">移除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </div>
          <el-card v-else>
            <el-form :model="queryParams2" ref="queryForm2" size="small" :inline="true" label-width="68px">
              <el-form-item label="" prop="keyword">
                <el-input v-model="queryParams2.keyword" placeholder="请输入关键字" clearable style="width: 220px"
                          @keyup.enter.native="handleFilmQuery"/>
              </el-form-item>
              <el-form-item label="" prop="resType">
                <el-select v-model="queryParams2.resType" placeholder="选择类型" @change="handleFilmQuery"
                           style="width: 220px">
                  <el-option v-for="item in resTypeList" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleFilmQuery">搜索</el-button>
              </el-form-item>
            </el-form>
            <el-table ref="multipleTable" v-loading="loading2" :data="tableData2" height="500">
              <el-table-column label="id" align="center" prop="id" width="55"></el-table-column>
              <el-table-column label="标题" align="center" prop="title" show-overflow-tooltip></el-table-column>
              <el-table-column label="封面" align="center" prop="thumbUrl" width="120">
                <template slot-scope="scope">
                  <el-image v-if="[1, 2, 3, 5].indexOf(queryParams2.resType) > -1" fit="cover"
                            style="width: 54.5px; height: 73.5px"  :src="scope.row.thumbUrl||scope.row.coverImg"
                      :preview-src-list="[scope.row.thumbUrl||scope.row.coverImg]"
                       :data-src="scope.row.thumbUrl||scope.row.coverImg" lazy></el-image>
                  <el-image v-if="queryParams2.resType === 4" fit="cover" style="width: 54.5px; height: 73.5px"
                            :src="scope.row.coverUrl" :preview-src-list="[scope.row.coverUrl]" lazy></el-image>
                  <el-carousel indicator-position="none" arrow="never" height="50px"
                               v-if="queryParams2.resType === 6 && scope.row.thumbnailUrls">
                    <el-carousel-item v-for="item in scope.row.thumbnailUrls.split(',')" :key="item">
                      <el-image fit="cover" style="width: 54.5px; height: 73.5px" :src="item"></el-image>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </el-table-column>
              <el-table-column label="资费" align="center">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.vip > 0" type="success" size="mini">VIP</el-tag>
                  <el-tag v-else-if="scope.row.price > 0" type="danger" size="mini">付费({{ scope.row.price }}金币)</el-tag>
                  <el-tag v-else size="mini">免费</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button size="mini" icon="el-icon-plus" type="primary" @click="handleFilmAdd(scope.row)">添加
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <pagination small layout="prev, pager, next" v-show="total2 > 0" :total="total2"
                        :page.sync="queryParams2.pageNum" :limit.sync="queryParams2.pageSize"
                        @pagination="getFilmList"/>
          </el-card>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <div class="editor" ref="editor" :style="styles"></div>
  </div>
</template>

<script>
  import Quill from "quill";
  import "quill/dist/quill.core.css";
  import "quill/dist/quill.snow.css";
  import "quill/dist/quill.bubble.css";
  import "./quillEditor.css";
  import {getToken} from "@/utils/auth";

  import {add, edit} from "@/api/content/homeRecommend";
  import {query as filmQuery} from "@/api/film/list";
  import {query as cartoonQuery} from "@/api/cartoon/list";
  import {query as novelQuery} from "@/api/novel/list";
  import {query as imageQuery} from "@/api/image/list";
  import {list as clipQuery} from "@/api/clip/list";
  import {list as momentQuery} from "@/api/moment/list";
  import {appendDomain} from "@/utils/convertUrl.js";

  import FileBlot from "./Link"
  import video from "./video"

  Quill.register(FileBlot, true);
  Quill.register(video, true);
  // 自定义的字体，注意下面的内容要和css里面对应上
  var fontFamily = [
    "SimSun",
    "SimHei",
    "Microsoft-YaHei",
    "KaiTi",
    "FangSong",
    "Arial",
    "Times-New-Roman",
    "sans-serif",
  ];
  Quill.imports['attributors/style/font'].whitelist = fontFamily;
  Quill.register(Quill.imports['attributors/style/font']);
  // 自定义字号的大小，注意下面的内容要和css里面对应上
  var fontSize = [false, "16px", "18px", "20px", "22px", "26px", "28px", "30px", "34px", "36px", "38px",
    "40px", "42px", "44px", "46px", "48px", "50px", "52px", "54px", "56px", "58px", "60px"];
  Quill.imports['attributors/style/size'].whitelist = fontSize;
  Quill.register(Quill.imports['attributors/style/size']);
  import htmlEditButton from "quill-html-edit-button";

  Quill.register({
    "modules/htmlEditButton": htmlEditButton
  })
  export default {
    name: "Editor",
    props: {
      /* 编辑器的内容 */
      value: {
        type: String,
        default: "",
      },
      /* 高度 */
      height: {
        type: Number,
        default: 800,
      },
      /* 最小高度 */
      minHeight: {
        type: Number,
        default: 100,
      },
      /* 只读 */
      readOnly: {
        type: Boolean,
        default: false,
      },
      // 上传文件大小限制(MB)
      fileSize: {
        type: Number,
        default: 5,
      },
       // 上传视频文件大小限制(MB)
       videoFileSize: {
        type: Number,
        default: 100,
        required:false
      },
      /* 类型（base64格式、url格式） */
      type: {
        type: String,
        default: "url",
      }
    },
    data() {
      return {
        uploadUrl: `/file/uploadImage`, // 上传的图片服务器地址
        uploadUrlVideo:'/file/uploadVideo',//上传视频服务器地址
        headers: {
          Authorization: "Nasinet " + getToken()
        },
        headersVideo: {
          Authorization: "Nasinet " + getToken()
        },
        Quill: null,
        currentValue: "",
        options: {
          theme: "snow",
          bounds: document.body,
          debug: "warn",
          modules: {
            // html源代码编辑
            htmlEditButton: {
              debug: false, // logging, default:false
              msg: "在这里修改HTML代码，点击完成将替换编辑器的内容", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
              okText: "完成", // Text to display in the OK button, default: Ok,
              cancelText: "取消", // Text to display in the cancel button, default: Cancel
              buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
              buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
              syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
              prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
              editorModules: {} // The default mod
            },
            // 工具栏配置
            toolbar: {
              container: [
                ["bold", "italic", "underline", "strike"],       // 加粗 斜体 下划线 删除线
                // ["blockquote", "code-block"],                    // 引用  代码块
                // [{ list: "ordered" }, { list: "bullet" }],       // 有序、无序列表
                // [{ indent: "-1" }, { indent: "+1" }],            // 缩进
                // [{ size: ["small", false, "large", "huge"] }],   // 字体大小
                [{size: fontSize}], // 配置字号
                [{font: fontFamily}], //显示字体选择
                // [{ header: [1, 2, 3, 4, 5, 6, false] }],         // 标题
                [{color: []}, {background: []}],             // 字体颜色、字体背景颜色
                // [{ align: [] }],                                 // 对齐方式
                ["clean"],                                       // 清除文本格式
                ["image", "video", "link"],                               // 链接、图片
                ["source"]
              ],
              handlers: {
                //给自定义按钮添加的事件
                source: () => {
                  //撤销
                  this.showSource = true;
                  this.getFilmList()
                },

              },
            },
          },
          placeholder: "请输入内容",
          readOnly: this.readOnly,

        },
        showSource: false,
        // 表单参数
        form: {
          title: undefined,
          banner: undefined,
          sort: 0,
          resType: 1,
          resid: undefined,
          resource: undefined,
        },
        tableData2: [],
        queryParams2: {
          keyword: "",
          pageNum: 1,
          pageSize: 10,
          sort: 1,
          status: 2,
          tag: undefined,
          type: 0,
          resType: 1,
        },
        total2: 0,
        loading2: true,
        resTypeList:
          [
            {
              value: 1,
              label: "电影",
            },
            {
              value: 2,
              label: "剧情",
            },
            {
              value: 3,
              label: "小说",
            },
            {
              value: 4,
              label: "图集",
            },
            // {
            //   value: 5,
            //   label: "短视频",
            // },
            // {
            //   value: 6,
            //   label: "动态",
            // },
          ],
        // 表单校验
        rules: {
          title: [
            {required: true, message: "链接文字不能为空", trigger: "blur"},
          ],
          resource: [{required: true, message: "请选择电影", trigger: "blur"}],
        },
      };
    },
    computed: {
      styles() {
        let style = {};
        if (this.minHeight) {
          style.minHeight = `${this.minHeight}px`;
        }
        if (this.height) {
          style.height = `${this.height}px`;
        }
        return style;
      }
      ,
    }
    ,
    watch: {
      value: {
        handler(val) {
          if (val !== this.currentValue) {
            this.currentValue = val === null ? "" : val;
            if (this.Quill) {
              this.Quill.pasteHTML(this.currentValue);
            }
          }
        }
        ,
        immediate: true,
      }
      ,
    }
    ,
    mounted() {
      this.init();
      this.initButton();
    }
    ,
    beforeDestroy() {
      this.Quill = null;
    }
    ,
    methods: {
      // 移除电影
      handleFilmRemove() {
        this.form.resource = undefined;
      }
      ,
      // 添加电影
      handleFilmAdd(row) {
        this.form.resource = row;
        this.form.resType = this.queryParams2.resType;
        this.form.title = this.form.title ? this.form.title : row.title
      }
      ,
      // 表单重置
      reset() {
        this.form = {
          title: undefined,
          subTitle: undefined,
          banner: undefined,
          sort: 0,
          resType: 1,
          resid: undefined,
          resource: undefined,
        };
        this.resetForm("form");
        this.resetForm("queryForm");
        this.getFilmList();
      }
      ,
      // 取消按钮
      cancel() {
        this.showSource = false;
        this.reset();
      }
      ,
      /** 提交按钮 */
      submitForm: function () {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            let params = this.deepClone(this.form);
            console.log(params);
            // 获取富文本组件实例
            let quill = this.Quill
            // 插标签入内容
            // 获取光标所在位置
            let range = quill.getSelection(true)
            const linkText = params.title // 输入的超链接



let data = {
  resType: params.resType,
  resource: {
    id: params.resource.id,
    videoPath:params.resType===1?params.resource.videoPath.replaceAll(process.env.VUE_APP_ZY_URL,''):""
  },
};



// const node = { type: 'link',url: `http://app.mksahu1l0.com`+encodeURIComponent(`?data=${JSON.stringify(data)}`) , children: [{ text: params.title }] }
// this.$emit("submit",node);


            quill.insertEmbed(range.index, 'link', {
              href: `http://app.mksahu1l0.com`+encodeURIComponent(`?data=${JSON.stringify(data)}`) ,
              innerText: linkText
            }, "api")
            quill.setSelection(range.index + linkText.length)   // 调整光标到最后
            this.cancel()
          }
        });
      }
      ,
      /** 搜索按钮操作 */
      handleFilmQuery() {
        this.queryParams2.pageNum = 1;
        this.getFilmList();
      }
      ,
      /** 查询电影列表 */
      getFilmList() {
        this.loading2 = true;
        let params = {
          keyword: this.queryParams2.keyword,
          pageNum: this.queryParams2.pageNum,
          pageSize: this.queryParams2.pageSize,
          status: this.queryParams2.status,
          tag: this.queryParams2.tag,
          type: this.queryParams2.type,
          sort: this.queryParams2.sort,
        };
        if (this.queryParams2.resType === 1) {
          filmQuery(params).then((response) => {
            this.tableData2 = response.data.list;
            this.total2 = response.data.totalCount;
            this.loading2 = false;
          });
        }
        if (this.queryParams2.resType === 2) {
          cartoonQuery(params).then((response) => {
            this.tableData2 = response.data.list;
            this.total2 = response.data.totalCount;
            this.loading2 = false;
          });
        }
        if (this.queryParams2.resType === 3) {
          novelQuery(params).then((response) => {
            this.tableData2 = response.data.list;
            this.total2 = response.data.totalCount;
            this.loading2 = false;
          });
        }
        if (this.queryParams2.resType === 4) {
          imageQuery(params).then((response) => {
            this.tableData2 = response.data.list;
            this.total2 = response.data.totalCount;
            this.loading2 = false;
          });
        }
        if (this.queryParams2.resType === 5) {
          clipQuery(params).then((response) => {
            this.tableData2 = response.data.list;
            this.total2 = response.data.totalCount;
            this.loading2 = false;
          });
        }
        if (this.queryParams2.resType === 6) {
          momentQuery(params).then((response) => {
            this.tableData2 = response.data.list;
            this.total2 = response.data.totalCount;
            this.loading2 = false;
          });
        }
      }
      ,
      initButton() {
        //在使用的页面中初始化按钮样式
        let source = document.querySelector(".ql-source"); //获取元素
        let Front = document.createElement("i"); //创建元素
        Front.className = "el-icon-circle-plus-outline"; //新增class属性
        Front.style.cssText = "font-size:18px";
        source.appendChild(Front); //追加到元素中
        // document.querySelector('a.ql-link').addEventListener('click', (event) => {
        //   console.log(event);
        // });
      }
      ,
      init() {
        const editor = this.$refs.editor;
        this.Quill = new Quill(editor, this.options);

        // 如果设置了上传地址则自定义图片上传事件
        if (this.type == 'url') {
          let toolbar = this.Quill.getModule("toolbar");
          toolbar.addHandler("image", (value) => {
            this.uploadType = "image";
            if (value) {
              this.$refs.upload.$children[0].$refs.input.click();
            } else {
              this.quill.format("image", false);
            }
          });
          toolbar.addHandler("video", (value) => {
            this.uploadType = "video";
            if (value) {
              this.$refs.uploadVideo.$children[0].$refs.input.click();
            } else {
              this.quill.format("video", false);
            }
          });
        }
        this.Quill.pasteHTML(this.currentValue);
        this.Quill.on("text-change", (delta, oldDelta, source) => {
          const html = this.$refs.editor.children[0].innerHTML;
          const text = this.Quill.getText();
          const quill = this.Quill;
          this.currentValue = html;
          this.$emit("input", html);
          this.$emit("on-change", {html, text, quill});
        });
        this.Quill.on("text-change", (delta, oldDelta, source) => {
          this.$emit("on-text-change", delta, oldDelta, source);
        });
        this.Quill.on("selection-change", (range, oldRange, source) => {
          this.$emit("on-selection-change", range, oldRange, source);
        });
        this.Quill.on("editor-change", (eventName, ...args) => {
          this.$emit("on-editor-change", eventName, ...args);
        });
      }
      ,
      textLink(text, link) {
        console.log(arguments);
        this.showTextLink = true
      }
      ,
      // 上传前校检格式和大小
      handleBeforeUpload(file) {
        // 校检文件大小
        if (this.fileSize) {
          const isLt = file.size / 1024 / 1024 < this.fileSize;
          if (!isLt) {
            this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`);
            return false;
          }
        }
        this.$modal.loading("图片上传中...");
        return true;
      }
      ,
      handleUploadSuccess(res, file) {
        console.log(res, file);
        // 获取富文本组件实例
        let quill = this.Quill;
        // 如果上传成功
        if (res.code == 200) {
          // 获取光标所在位置
          let length = quill.getSelection().index;
          // 插入图片  res.url为服务器返回的图片地址
          appendDomain(res.data);
          quill.insertEmbed(length, "image", res.data.orgUrl);
          this.$emit("imageChange", res.data)
          // 调整光标到最后
          quill.setSelection(length + 1);
          this.$modal.closeLoading();
        } else {
          this.$message.error("图片插入失败");
          this.$modal.closeLoading();
        }
      }
      ,
      handleUploadError() {
        this.$message.error("图片插入失败");
        this.$modal.closeLoading();
      }
      ,
      handleBeforeUploadVideo(file) {
        // 校检文件大小
        if (this.videoFileSize) {
          const isLt = file.size / 1024 / 1024 < this.videoFileSize;
          if (!isLt) {
            this.$message.error(`上传文件大小不能超过 ${this.videoFileSize} MB!`);
            return false;
          }
        }
        this.$modal.loading("视频上传中...");
        return true;
      }
      ,
      handleUploadSuccessVideo(res, file) {
        console.log(res, file);
        // 获取富文本组件实例
        let quill = this.Quill;
        // 如果上传成功
        if (res.code == 200) {
          // 获取光标所在位置
          let length = quill.getSelection().index;
          // 插入图片  res.url为服务器返回的图片地址
          appendDomain(res.data);
          quill.insertEmbed(length, "video", res.data.orgUrl);
          this.$emit("videoChange", res.data)
          // 调整光标到最后
          quill.setSelection(length + 1);
          this.$modal.closeLoading();
        } else {
          this.$message.error("视频插入失败");
          this.$modal.closeLoading();
        }
      }
      ,
      handleUploadErrorVideo() {
        this.$message.error("视频插入失败");
        this.$modal.closeLoading();
      }
      ,
    }
    ,
  }
  ;
</script>

<style lang="scss" scoped>
  .editor,
  .ql-toolbar {
    white-space: pre-wrap !important;
    line-height: normal !important;
  }

  .quill-img {
    display: none;
  }

  ::v-deep .ql-snow .ql-tooltip::before {
    content: "链接地址";
  }

  ::v-deep .ql-snow .ql-tooltip[data-mode="link"]::before {
    content: "请输入链接地址:";
  }

  ::v-deep .ql-snow .ql-tooltip a.ql-action::after {
    content: "编辑";
  }

  ::v-deep .ql-snow .ql-tooltip a.ql-remove::before {
    content: "删除";
  }

  ::v-deep .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: "保存";
    padding-right: 0px;
  }

  ::v-deep .ql-snow .ql-tooltip[data-mode="video"]::before {
    content: "请输入视频地址:";
  }

  ::v-deep .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  ::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: "文本";
  }

  ::v-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  ::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: "标题1";
  }

  ::v-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
  ::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: "标题2";
  }

  ::v-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
  ::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: "标题3";
  }

  ::v-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
  ::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: "标题4";
  }

  ::v-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
  ::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: "标题5";
  }

  ::v-deep .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
  ::v-deep .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: "标题6";
  }
</style>
