import request from '@/utils/request'

// 查询
export function list(param) {
  return request({
    url: '/system/content/home-recommend/list',
    method: 'get',
    params:param
  })
}

// 新增
export function add(data) {
  return request({
    url: '/system/content/home-recommend/add',
    method: 'post',
    data: data
  })
}

// 修改
export function edit(data) {
  return request({
    url: '/system/content/home-recommend/edit',
    method: 'post',
    data: data
  })
}

// 删除
export function del(data) {
  return request({
    url: '/system/content/home-recommend/del/' + data,
    method: 'delete',
  })
}
