import request,{postAxiosFormdata} from '@/utils/request'

// 根据类型标识查询对应设置(通用)
export function getCommonType(configType) {
    return request({
      url: '/system/configType/getType?configType=' + configType,
      method: 'get'
    })
  }

 //获取省市区
export function getCityList(parentId) {
  return request({
    url: '/system/area/getCityList?level=2&parentId=' + parentId,
    method: 'get'
  })
}
export function getProvinceList() {
  return request({
    url: '/system/area/getCityList?level=1',
    method: 'get'
  })
}
export function getAreaList(parentId) {
  return request({
    url: '/system/area/getCityList?level=3&parentId=' + parentId,
    method: 'get'
  })
}

export function fileUpload(data,fn){
    const url = '/file/uploadImage';
    return postAxiosFormdata(url, data,fn);
    /*
    {
      headers: {'Authorization':"Nasinet " +  Cookies.getToken(), 'Content-Type': 'multipart/form-data' }
    }

    */

}
export function fileUploadVideo(data,fn){
  const url = '/file/uploadVideo';
  return postAxiosFormdata(url, data,fn);
  /*
  {
    headers: {'Authorization':"Nasinet " +  Cookies.getToken(), 'Content-Type': 'multipart/form-data' }
  }

  */

}
