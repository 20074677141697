//引入Qill插件
import Quill from "quill";
// 源码中是import直接倒入，这里要用Quill.import引入
const Link = Quill.import("formats/link");
// 自定义a链接
class FileBlot extends Link {
  // 继承Link Blot
  static create (value) {
    let node = undefined;
    if (value && !value.href) {
      // 适应原本的Link Blot
      node = super.create(value)
    } else {
      // 自定义Link Blot
      node = super.create(value.href)
      node.href = value.href
      node.innerText = value.innerText
      // node.setAttribute('class', "ql-link");  // 左键点击即下载
    }
    return node;
  }
}
FileBlot.blotName = "link" // 这里不用改，如果需要也可以保留原来的，这里用个新的blot
FileBlot.tagName = "A"
export default FileBlot
// Quill.register(FileBlot) // 注册link